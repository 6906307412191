// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cennik-js": () => import("/opt/build/repo/src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dziekuje-js": () => import("/opt/build/repo/src/pages/dziekuje.js" /* webpackChunkName: "component---src-pages-dziekuje-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mnie-js": () => import("/opt/build/repo/src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-oferta-psychoterapia-dzieci-i-mlodziezy-js": () => import("/opt/build/repo/src/pages/oferta/psychoterapia-dzieci-i-mlodziezy.js" /* webpackChunkName: "component---src-pages-oferta-psychoterapia-dzieci-i-mlodziezy-js" */),
  "component---src-pages-oferta-psychoterapia-osob-doroslych-js": () => import("/opt/build/repo/src/pages/oferta/psychoterapia-osob-doroslych.js" /* webpackChunkName: "component---src-pages-oferta-psychoterapia-osob-doroslych-js" */),
  "component---src-pages-uslugi-js": () => import("/opt/build/repo/src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

